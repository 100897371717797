<template>
  <transition name="modal">
    <div class="modal-mask" @click="hide()" style="overflow-y: scroll;">
      <div v-if="getModalInfo" class="modal-container" @click.stop>

        <div class="close-div">
          <img @click="hide()" style="cursor:pointer;width: 24px; height: 24px;"
               :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
        </div>

        <div class="delete-account-top">
          <h4 class="modal-header">Bakiyemden <span>Para Çek 💰</span></h4>

          <div v-show="!finalStep">


            <div v-if="withdrawalAmount > 0" class="money-info">
              <div class="money-info-wrap">
                <div style="display: flex; align-items: center">
                  <img class="icon-wallet" src="https://gcdn.bionluk.com/site/cicons/ic-finance.svg" onload="SVGInject(this)"/>
                  <div style="margin-left: 10px;">
                    <p class="title">{{ withdrawalAmount |number_format(2) }} TL</p>
                    <p class="sub-title">Çekilebilir tutar</p>
                  </div>
                </div>
                <div style="display: flex; align-items: center">
                  <img class="icon-bank" src="https://gcdn.bionluk.com/site/cicons/ic-bank.svg" onload="SVGInject(this)"/>
                  <div style="margin-left: 10px;">
                    <p class="title">{{ibanNo}}</p>
                    <p class="sub-title">Hesap Sahibi: {{ibanName}}</p>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="sellerType === 'bireysel'">
              <div class="finance-info for-seller" v-if="!isAkbankIban">
                <div style="margin-left: 20px">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="for-seller-icon"/>
                </div>
                <div style="margin-left: 15px">
                  <div class="description">
                    <p class="disapproval-row">Sen de Akbank'a geç, komisyon indirimi ve maaş müşterisi avantajlarından yararlanlan! Kampanya detayları için <a class="link" href="https://bionluk.com/destek/akbankli-freelancerlara-ozel-kampanya-103" target="_blank">tıkla!</a> 👀</p>
                  </div>
                </div>
              </div>
              <div v-else-if="!isAkbankUser" class="finance-info for-seller" style="height: 180px;">
                <div style="margin-left: 20px">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="for-seller-icon"/>
                </div>
                <div style="margin-left: 15px">
                  <div class="description">
                    <p class="disapproval-row">
                    <div class="description">
                      <p class="disapproval-row">Hey! Akbanklı freelancerlarımıza sağladığımız komisyon indirimi ve maaş müşterisi avantajlarından yararlanlanmak ister misin? 👀</p>
                      <p><custom-button @click="showAkbankModal()" style="margin-top: 20px" class="border" button-style="border-radius:4px; padding-bottom:2px; height:40px; width:300px">Kampanyaya Katılmak İstiyorum</custom-button></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="finance-info for-seller" v-else-if="isAkbankUser" style="height: 120px">
                <div style="margin-left: 20px">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="for-seller-icon"/>
                </div>
                <div style="margin-left: 15px">
                  <div class="description">
                    <p class="disapproval-row">Tebrikler! <a class="link" href="https://bionluk.com/destek/akbankli-freelancerlara-ozel-kampanya-103" target="_blank">Akbank kampanyası</a> listemizdesin! Komisyon indiriminden şu an yararlanıyorsun. Akbanklı freelancerlar'ın ilgili avantajlarının tanımlanması ise 7 iş günü içinde tamamlanıyor.</p>
                  </div>
                </div>
              </div>
            </template>

            <div style="margin-top: 10px;">
              <p class="info-text">Önemli: <span class="info-description" style="font-weight: 400">Sadece kendi adına veya şirketin adına açılmış bir banka hesabına para çekme gönderme bulunabilirsin. Banka bilgilerini <router-link style="color: #fd4056" to="/settings/finance">buraya tıklayarak</router-link> güncelleyebilirsin.</span></p>
            </div>

            <custom-input label="Çekmek İstediğin Tutar"
                          inputType="number"
                          @focus="handleFocusIn"
                          @blur="handleFocusOut"
                          v-model="withdrawAmountText"
                          :valid="withdrawAmount ? true : null"
                          style="width: 492px; margin-top: 40px;"/>

            <div style="width:495px;justify-self: flex-start; text-align: left; align-items: flex-start;">
              <custom-check
                :label="'<span style=\'font-size:15px; color:#5e6b79 \'>Hesabımdaki tüm parayı çek</span>'"
                :id="'x--id'"
                style="margin-top: 30px;"
                :checked="withdrawAllChecked"
                :value="withdrawAllChecked"
                @input="selectWithdrawAll()"
                :single-gig="{}"
                :labelStyle="' font-size:18px!important;  line-height: 1.63; '"
              />

            </div>
            <div class="delete-account-bottom" style="margin-top: 30px;">
              <custom-button buttonType="border" style="width: 100px; height: 44px; font-weight: 500;" :disabled="disableButton" @click="hide()">İptal</custom-button>
              <custom-button buttonType="dark" style="width: 140px; height: 44px; padding-bottom: 2px; margin-left: 30px; font-weight: 500;" :disabled=" !this.withdrawAmountText" @click="nextStep()">Devam Et</custom-button>
            </div>


          </div>

          <div v-show="finalStep" style="text-align: center; width: 550px; margin: 0 auto 20px;">

            <div style="margin-top:50px; text-align: left ">
              <div class="bank-info" >
                <div class="contact-item">
                  <label class="contact-label left">Hesap Sahibi</label>
                  <p class="contact-text right" style="text-transform: capitalize;font-weight: 800;">{{ibanName}}</p>
                </div>
                <div class="separator" style="margin-top: 17px; margin-bottom: 17px;"></div>
                <div class="contact-item">
                  <label class="contact-label left">IBAN</label>
                  <p class="contact-text right" style="font-weight: 800;">{{ibanNo}}</p>
                </div>
                <div class="separator" style="margin-top: 17px; margin-bottom: 17px;" ></div>
                <div class="contact-item">
                  <label class="contact-label left">Tutar</label>
                  <p class="contact-text right" style="font-weight: 800;">{{ withdrawAmount }} ₺</p>
                </div>
              </div>
            </div>

            <div style=" text-align: left; margin-top: 50px;">
              <p class="info-text">Önemli: <span class="info-description" style="font-weight: 300">Paranın sana ulaşması için sisteme girdiğin kullanıcı bilgilerin <span style="font-weight: 600; color: #2D3640;">(İsim, Soyisim, T.C. Kimlik</span> ve <span style="font-weight: 600; color: #2D3640;">IBAN numarası)</span> ile bankada kayıtlı hesap bilgilerinin birebir uyuşması gerekiyor. Sadece kendi adına veya şirketin adına açılmış bir hesaba transfer yapılabilir. Banka bilgilerini <router-link style="color: #fd4056" to="/settings/finance">buraya tıklayarak</router-link> güncelleyebilirsin.</span></span></p>
            </div>

            <div style=" text-align: left;">
              <p style="margin-top: 20px; color: #fa4359; font-weight: 300; font-size: 13px;">Para transferi 2-5 iş günü içinde tamamlanır.</p>

            </div>

            <custom-button buttonType="green" style="width: 240px; height: 44px; padding-bottom: 2px; margin-top: 40px; font-weight: 500;" :disabled="disableButton || !this.withdrawAmount" @click="withdrawProcess()">Para Çekme İşlemini Başlat</custom-button>

          </div>


        </div>
      </div>
      </div>
  </transition>


</template>

<script>


  //import LogRocket from 'logrocket';
  import CustomInput from "../../components/CustomInput";
  import CustomButton from "../../components/CustomButton";
  import CustomCheck from "@/pages/components/CustomCheck";
  import EventBus from "@/eventBus";

  export default {
    name: "src-pages-modals-closeAccount-v1",
    components: {
      CustomButton,
      CustomInput,
      CustomCheck
    },
    data () {
      return {

        disableButton: false,

        ibanNo:'',
        ibanName:'',
        isAkbankIban: false,
        isAkbankUser: false,
        sellerType: '',
        walletAmount: 0,
        withdrawalAmount: 0,
        withdrawAmount: null,
        withdrawAmountText: '',

        withdrawAllChecked:false,
        finalStep:false,
    }
    },
    methods: {

      showAkbankModal(){
        const modalObj = {
          title: 'Akbank Freelancer Kampanyası',
          bodyText:
            `Hesap Sahibi: <span style="font-weight: bold">${this.ibanName} </span><br>
              IBAN: <span style="font-weight: bold">${this.ibanNo}</span><br><br>
               <span style="color: #3a4653; font-size: 14px;">Kampanyaya katılımını gerçekleştirdikten sonra, komisyon indiriminden yararlandığın hakedişlerin tamamını yukarıda belirtilen IBAN hesabına çekmeden IBAN bilgini değiştiremezsin. <br><br>Akbanklı freelancerlar her pazartesi belirleniyor ve ilgili avantajların tanımlanması 7 iş gününü bulabiliyor. Akbank hesabını tanımladıktan sonra Bionluk'tan alacağın 2. ödemeden itibaren avantajlarını kullanabileceksin. <br>Kampanya 31 Aralık 2024 tarihine kadar devam edecek. 🥳</span>`,
          actionButton: {
            show: true,
            title: 'Katıl',
            url: '',
            cancelButtonTitle: 'İptal',
            eventName: 'updateAkbankEvent'
          }

        };
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.WARNING_MODAL, info: {...modalObj}});
      },

      updateAkbankUser(){

        this.api.content.userAkbank()
          .then(({data}) => {
            let result = data;
              if(result.success) {
                this.$toasted.global.infoToast({description: result.message});
                location.reload();
              } else {
                this.$toasted.global.errorToast({description: result.message});
              }

          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});

          });
      },

      handleFocusOut(){

        if(this.withdrawAmountText){
          this.withdrawAmountText = this.withdrawAmountText.replace(",", ".");
          this.withdrawAmountText = parseFloat(this.withdrawAmountText).toFixed(2);
        }

        if(isNaN(this.withdrawAmountText)){
          this.withdrawAmountText = '';
          this.withdrawAmount = null;
        } else {
          this.withdrawAmount = this.withdrawAmountText;

          if(this.withdrawAmount > this.withdrawalAmount){
            this.withdrawAmountText = '';
            this.withdrawAmount = null;
            this.$toasted.global.errorToast({description: 'Yetersiz bakiye. En fazla '+ this.withdrawalAmount + ' TL çekebilirsin.'});
          } else {
            this.withdrawAmountText = this.withdrawAmountText.replace(".", ",");
            this.withdrawAmountText += ' ₺';
          }
        }
      },
      handleFocusIn(){
        this.withdrawAmountText = this.withdrawAmountText.replace(' ₺', '');
      },

      selectWithdrawAll(){

        this.withdrawAllChecked = !this.withdrawAllChecked;
        if(!this.withdrawAllChecked){
          this.withdrawAmount = null;
          this.withdrawAmountText = '';
        } else {

          this.withdrawAmountText = this.withdrawalAmount.toString();
          this.withdrawAmountText.replace(".", ",")
          this.handleFocusOut()
        }

      },
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },


      nextStep(){
        this.finalStep = true;

      },

      withdrawProcess() {

        this.disableButton = true;
        this.api.user.withdrawFromWallet(this.withdrawAmount)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$toasted.global.infoToast({description: result.message});
              EventBus.$emit("refreshWallet");
              this.hide();
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.disableButton = false;
            }
          })
          .catch(() => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.disableButton = false;
          });
      }
    },

    computed: {
      getModalInfo() {

        const modalData = this.$store.state.activeModal.info.data;
        this.ibanName = modalData.ibanName;
        this.ibanNo = modalData.ibanNo;
        this.isAkbankIban = modalData.isAkbankIban;
        this.isAkbankUser = modalData.isAkbankUser;
        this.sellerType = modalData.sellerType;
        this.withdrawalAmount = modalData.withdrawalAmount;
        this.walletAmount = modalData.walletAmount;
        return true;
      }
    },

    created() {
      this.EventBus.$off("updateAkbankEvent");
      this.$nextTick(() => {
        this.EventBus.$on('updateAkbankEvent',  ()=> {
          this.updateAkbankUser();
        });
      })
    },

  }

</script>

<style scoped lang="scss">
.finance-info{
  width: 500px;
  height: 100px;
  border-radius: 10px;
  border: solid 1px rgba(242, 153, 51, 0.3);
  background-color: rgba(242, 153, 51, 0.2);
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;

  .disapproval-row{
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: white;
    .link{
      font-weight: 600;
      color: white;
      text-decoration: underline;
    }
  }

  &.for-seller {
    border: solid 1px rgba(90, 38, 164, 0.3);
    background-color: rgba(255, 36, 00, 1);
  }
  .for-seller-icon /deep/{
    width: 36px;
    height: 36px;
    path{
      fill: white;
    }
  }
  .for-buyer-icon /deep/{
    width: 36px;
    height: 36px;
    path{
      fill: #f29933;
    }
  }
  .delete-icon /deep/{
    width: 16px;
    height: 16px;
    path{
      fill: #2d3640;
    }
  }
  .title{
    font-size: 16px;
    font-weight: 500;
    color: #343d4a;
    padding-bottom: 4px;
  }
  .description{
    width: 408px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.29;
    color: #4f596c;
  }
}


.hover-colred{
  color: #fa4359;  font-weight: 600; cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
.info-text {
  font-size: 16px;
  color: #2d3640;
  font-weight:600;
  margin-top: 12px;
  line-height: 1.44;
}


.bank-info {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.separator {
  border-bottom: 1px solid #eaedf2;
  width: 100%;
}
.contact-item {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px;

  .left {
    width: 25%;
    align-self: center;
  }

  .right {
    width: 75%;
  }
}

.contact-label {
  font-size: 16px;
  color: #8b95a1;
  font-weight: 400;
  margin: 0;
}

.contact-text {
  font-size: 16px;
  color: #2d3640;
  font-weight: 400;
}


  .money-info{
    border-radius: 10px;
    background-color: rgba(244, 245, 247,0.7);
    width: 495px;
    height: 95px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .money-info-wrap{
      width: 435px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .title{
        font-size: 16px;
        font-weight: 600;
        color: #2D3640;
      }
      .sub-title{
        font-size: 12px;
        font-weight: 300;
        color: #8b95a1;
      }
    }

    .icon-wallet /deep/{
      width: 24px;
      height: 24px;
      path{
        fill: #8B95A1;
      }
    }

    .icon-bank /deep/{
      width: 23px;
      height: 25px;
      path{
        fill: #8B95A1;
      }
    }

    .update-info{
      font-size: 18px;
      color: #a1a9a9;
    }
  }

  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }


  .modal-container {
    width: calc(100vw - 20px) !important;
    max-height: 685px;
    max-width: 655px;
    font-family: 'sofia-pro';
    overflow-y: scroll;

    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px #ffffff;
    padding: 50px 0 30px 0;
    position: relative;
  }

  .close-div {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0.75;
  }

  .close-div:hover {
    opacity: 1;
  }

  .delete-account-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 85px;
    padding-right: 85px;
  }

  .modal-header {
    font-size: 32px;
    color: #2d3640;
    font-weight: 400;

    span {
      font-weight: 600;
    }
  }

  .user-avatar {
    width: 84px;
    height: 84px;
    position: relative;
    margin-top: 11px;
  }

  .user-avatar-img {
    box-sizing: border-box;
    border: 2px solid #eaeaea;
    border-radius: 100%;
    max-width: calc(100% - 2px);
    height: auto;
  }

  .info-text {
    font-size: 16px;
    color: #2d3640;
    font-weight: 800;
    margin-top: 12px;
  }

  .info-description {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #5e6b79;
    margin-top: 5px;
  }


  .seperator {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    border-bottom: 1px solid #bfc8d2;
  }

  .delete-account-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
